import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#8D4484`,
      marginBottom: `1.45rem`,
      minHeight: 100,
    }}
  >
      {/*<h1 style={{ margin: 0 }}>*/}
      {/*  <Link*/}
      {/*    to="/"*/}
      {/*    style={{*/}
      {/*      color: `white`,*/}
      {/*      textDecoration: `none`,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {siteTitle}*/}
      {/*  </Link>*/}
      {/*</h1>*/}
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
